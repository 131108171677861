import TagManager from 'react-gtm-module';

export const phoneRegex = /^\+?[0-9()-]{7,}[0-9]$/;

export const sendGtmEvent = (eventName: string, eventData: object = {}) => {
  TagManager.dataLayer({
    dataLayer: {
      event: eventName,
      ...eventData
    }
  });
};