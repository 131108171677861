import React, { useContext, useState, useEffect } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  Routes,
  RouterProvider,
  useLocation,
} from 'react-router-dom';
import Layout from './components/Layouts/Layout';
import { NotFound } from './pages/NotFound';
import Signup from './pages/auth/Signup';
import Login from './pages/auth/Login';
import TagManager from 'react-gtm-module';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme/theme';
import ConfirmSignup from './pages/auth/ConfirmSignup';
import ResetPasswordRequest from './pages/auth/ResetPasswordRequest';
import ResetComplete from './pages/auth/ResetComplete';
import LayoutDashboard from './components/Layouts/LayoutDashboard';
import { Service } from './pages/Service';
import { AuthProvider } from './hooks/useAuth';
import { PUBLIC_STRIPE_API_KEY, STORAGE_USER } from './constants/storage';
import Settings from './pages/Settings';
import { Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js'
import { Clients } from './pages/Clients';
import { Questionnaire } from './pages/Questionnaire';
import ResetPassword from './pages/Settings/ResetPassword';
import { ProtectedRoute } from './components/Auth/ProtectedRoute';

const stripePromise = loadStripe(PUBLIC_STRIPE_API_KEY || '');

function App() {
  const [user, setUser] = useState<any>(null);
  const location = useLocation();

  const options: StripeElementsOptions | undefined = {
    mode: 'setup',
    currency: 'usd',
  };

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: 'GTM-T73633SX'
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  useEffect(() => {
    const pagePath = location.pathname + location.search;
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        pagePath: pagePath,
        pageTitle: document.title,
        pageLocation: window.location.href,
      },
    });

    if (localStorage && localStorage.getItem(STORAGE_USER)) {
      setUser(JSON.parse(localStorage.getItem(STORAGE_USER) || ''));
    }
  }, [location, location.pathname]);
  // const roles = useRoles();

  // if (!roles.length) {
  //   return <LoadingPage />;
  // }

  const isAdmin = user?.role === 'ADMIN';
  const isClient = user?.role === 'CLIENT';

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider storageValue={user || null}>
        <Routes>
          <Route element={<Layout />}>
            <Route index element={<Login />} />
            <Route path="login" element={<Login />} />
            <Route path="signup" element={<Signup />} />
            <Route path="verify" element={<ConfirmSignup />} />
            <Route path="request-reset" element={<ResetPasswordRequest />} />
            <Route path="reset-complete" element={<ResetComplete />} />
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route element={
              <Elements stripe={stripePromise} options={options}>
                <LayoutDashboard />
              </Elements>
            }>
              <Route path="/" element={<Navigate to="/dashboard" />} />
              <Route path="dashboard">
                {isAdmin ? (
                  <>
                    <Route index element={<Clients />} />
                  </>
                ) : (
                  isClient && <>
                    <Route index element={<Service />} />
                    <Route path="questionnaire/:id" element={<Questionnaire />} />
                  </>
                )}
                <Route path="settings">
                  <Route index element={<Settings />} />
                  <Route path="password" element={<ResetPassword />} />
                </Route>
                <Route path="*" element={<NotFound />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </AuthProvider>
    </ThemeProvider >
  );
}

export default App;
