import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { emailValidation, passwordConfirmRegisterValidation, passwordRegisterValidation } from './validation';
import {
  filledInputClasses,
  Box,
  useTheme,
  Button,
  Typography,
  FormControlLabel,
  Link
} from '@mui/material';
import { InputText } from '../../elements/InputText';
import InputPassword from '../../elements/InputPassword';
import { useResponseError } from '../../hooks/useResponseError';
import { STORAGE_AUTH_TOKEN, STORAGE_USER } from '../../constants/storage';
import { loginAuth } from '../../services/authService';
import { useAuth } from '../../hooks/useAuth';
import { getUserData } from '../../services/userService';
import { ErrorBox } from '../../elements/ErrorBox';
import { sendGtmEvent } from '../../utils';

export const LoginForm = () => {
  const { login } = useAuth();
  const { palette, typography } = useTheme();
  const [checked, setChecked] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { responseError, setResponseError } = useResponseError();

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: any): Promise<any> => {
    try {
      sendGtmEvent('login_attempt', {
        email: data?.email,
      });
			const res = await loginAuth(data);
			login(res.accessToken);
			const resUserData = await getUserData({
				token: res?.accessToken
			});
			localStorage.setItem(STORAGE_AUTH_TOKEN, res?.accessToken);

			if(resUserData){
				localStorage.setItem(STORAGE_USER, JSON.stringify(resUserData));
        navigate(`/dashboard`, {
          replace: true
        });
				window.location.reload();
			}
		} catch (error: unknown) {
      sendGtmEvent('login_error', {
        email: data?.email,
        error: (error as Error).message
      });
			setResponseError((error as Error).message);
		}
  };

  return (
    <Box
      sx={{
        maxWidth: '315px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <Box
        sx={{
          width: '100%'
        }}
        component="form"
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Typography
          align="center"
          variant="h1"
          sx={{
            mb: '32px'
          }}
        >
          Login
        </Typography>
        <Box>
          <InputText
            inputProps={{ ...register('email') }}
            type='email'
            label='Email address'
            placeholder='Email address'
            error={!!errors['email']}
            helperText={errors['email'] ? errors['email'].message : ''}
            fullWidth
          />
        </Box>
        <Box sx={{ mb: '16px' }}>
          <InputPassword
            inputProps={{ ...register('password') }}
            placeholder='Password'
            error={!!errors['password']}
            fullWidth
          />
          {errors['password'] && <ErrorBox
            sx={{
              mb: '8px',
              textAlign: 'left',
            }}>
            {errors['password'].message || 'Something went wrong'}
          </ErrorBox>
          }
        </Box>
        {responseError && <ErrorBox
          sx={{
            mb: '8px',
            textAlign: 'left',
          }}>
          {responseError || 'Something went wrong'}
        </ErrorBox>
        }
        <Button fullWidth variant="contained" type="submit">Login</Button>
        <Box sx={{
          mt: '18px',
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center'
        }}>
          <Link
            sx={{}}
            href="/request-reset">
            Forgot password?
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

const schema = yup.object().shape({
  email: emailValidation,
  password: passwordRegisterValidation,
});
