import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Outlet, Link, useLocation } from "react-router-dom";
import { Typography, SvgIcon } from "@mui/material";
import { SettingsLogout } from "../../components/Settings/SettingsLogout";
import { PersonalInfo } from "../../components/Settings/PersonalInfo";
import { ResetPassword } from "../../components/Settings/ResetPassword";
import { BillingInfo } from "../../components/Settings/BillingInfo";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { phoneRegex, sendGtmEvent } from "../../utils";
import {
  emailValidation,
  passwordRegisterValidation,
} from "../../components/Auth/validation";
import { STORAGE_PAYMENT_METHOD, STORAGE_USER } from "../../constants/storage";
import { getUserData, updateUserData } from "../../services/userService";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { ErrorBox } from "../../elements/ErrorBox";
import {
  addPaymentMethod,
  getPaymentMethod,
} from "../../services/paymentService";
import { useResponseSuccessMsg } from '../../hooks/useResponseSuccessMsg';
import TagManager from 'react-gtm-module';

const Settings = () => {
  const location = useLocation();
  const { responseStatus, setResponseStatus } = useResponseSuccessMsg(3000);
  const [changeCard, setChangeCard] = useState(false);
  const [isSuccessSaved, setIsSuccessSaved] = useState(false);
  const [userProfile, setUserProfile] = useState(
    JSON.parse(localStorage.getItem(STORAGE_USER) || '')
  );
  const [paymentMethod, setPaymentMethod] = useState();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [stripeErrorMessage, setStripeErrorMessage] = useState<string>("");
  const stripe = useStripe();
  const elements: any = useElements();

  const {
    control,
    register,
    handleSubmit,
    watch,
    setError,
    getValues,
    formState: { errors, dirtyFields },
    setValue,
  } = useForm({
    defaultValues: {
      serviceAreaId: "",
      saveMethod: false,
      termsService: false,
    },
    criteriaMode: "all",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (changeCard) {
      setIsSuccessSaved(false);
    }
  }, [changeCard]);

  useEffect(() => {
    const getUserObj = async () => {
      try {
        const res = await getUserData({});

        setUserProfile(res);
        localStorage.setItem(STORAGE_USER, JSON.stringify(res));
        if (userProfile?.role === "CLIENT") {
          const resPaymentMethod = await getPaymentMethod();
          if (resPaymentMethod) {
            setPaymentMethod(resPaymentMethod);
            localStorage.setItem(
              STORAGE_PAYMENT_METHOD,
              JSON.stringify(resPaymentMethod)
            );
          } else {
            setChangeCard(true);
          }
        }
      } catch (error: unknown) {
        console.error("error", error);
        setChangeCard(true);
      }
    };

    getUserObj();

    return () => { };
  }, []);

  const handleError = (error: unknown) => {
    setLoading(false);
    setStripeErrorMessage((error as Error).message);
    setErrorMessage((error as Error).message);
    setIsSuccessSaved(false);
  };

  const clearErrors = () => {
    setStripeErrorMessage("");
    setErrorMessage("");
  };

  const onSubmit = async (data: any): Promise<any> => {
    setResponseStatus(false);
    if (changeCard) {
      if (getValues("saveMethod") === false) {
        setError("saveMethod", { type: "custom", message: "This is required" });
      }


      if (getValues("termsService") === false) {
        setError("termsService", {
          type: "custom",
          message: "This is required",
        });
        setErrorMessage("Please accept the Terms of Service");
        return;
      }
    }

    try {
      const res = await updateUserData({
        ...data,
        id: userProfile.id,
      });

      localStorage.setItem(STORAGE_USER, JSON.stringify(res));

      if (!changeCard) {
        setIsSuccessSaved(true);
        setResponseStatus(true);
        clearErrors();
      }
    } catch (error: unknown) {
      setLoading(false);
      setErrorMessage((error as Error).message);
      setIsSuccessSaved(false);
    }

    // We don't want to let default form submission happen here,
    // which would refresh the page.
    // event.preventDefault();

    if (!stripe || !changeCard || userProfile?.role === "ADMIN") {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleError(submitError);
      return;
    }

    try {
      // Create the SetupIntent and obtain clientSecret
      const res = await addPaymentMethod({
        confirmedFutureCharges: data?.saveMethod,
      });

      sendGtmEvent('payment_method_addition', {
        category: 'payment',
        action: 'add',
        label: 'Adding a Payment Method',
        userId: userProfile?.id,
        firstName: userProfile?.firstName,
        lastName: userProfile?.lastName,
        email: userProfile?.email,
        paymentMethod: paymentMethod,
        paymentMethodAddedAt: new Date().toISOString(),
      });

      const clientSecret = res?.clientSecret;

      // Confirm the SetupIntent using the details collected by the Payment Element
      const { error } = await stripe.confirmSetup({
        elements,
        clientSecret,
        confirmParams: {
          return_url: window.location.href,
        },
      });

      if (error) {
        // This point is only reached if there's an immediate error when
        // confirming the setup. Show the error to your customer (for example, payment details incomplete)
        handleError(error);
      } else {
        // Your customer is redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer is redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
        setIsSuccessSaved(true);
        setResponseStatus(true);
        clearErrors();
      }
    } catch (error: unknown) {
      handleError(error);
    }
  };

  useEffect(() => {
    getUserObj(userProfile);

    return () => { };
  }, [userProfile]);

  const getUserObj = async (userProfile: any) => {
    if (userProfile) {
      try {
        const userObj = userProfile;
        setValue("email", userObj?.email, { shouldTouch: true });
        setValue("firstName", userObj?.firstName, { shouldTouch: true });
        setValue("lastName", userObj?.lastName, { shouldTouch: true });
        setValue("phoneNumber", userObj?.client?.phoneNumber, {
          shouldTouch: true,
        });
        setValue("serviceAreaId", userObj?.client?.serviceArea?.id, {
          shouldTouch: true,
        });
      } catch (error: unknown) {
        console.error("error", error);
      }
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{
          marginBottom: "90px",
          maxWidth: "315px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h1"
            sx={{
              mb: "32px",
            }}
          >
            Profile Settings
          </Typography>
        </Box>
        <Box sx={{ mb: "32px" }}>
          <PersonalInfo
            control={control}
            register={register}
            errors={errors}
            userProfile={userProfile}
          />
        </Box>
        {userProfile?.role === "CLIENT" && (
          <Box sx={{ mb: "32px" }}>
            <BillingInfo
              control={control}
              register={register}
              errors={errors}
              userProfile={userProfile}
              paymentMethod={paymentMethod}
              tripeErrorMessage={stripeErrorMessage}
              changeCard={changeCard}
              setChangeCard={setChangeCard}
            />
          </Box>
        )}
        <Box sx={{ mb: "32px" }}>
          {errorMessage && (
            <ErrorBox sx={{ ml: 0, mb: '20px' }}>{errorMessage}</ErrorBox>
          )}
          {responseStatus ? (
            <Box sx={{  mb: '20px', display: "flex", alignItems: "center" }}>
              <Typography>All changes are saved</Typography>
              <SvgIcon sx={{ ml: "8px" }}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="24" height="24" fill="white" />
                  <path
                    d="M5 10.75L10.7692 17L20 7"
                    stroke="#3D8F44"
                    strokeWidth="2"
                  />
                </svg>
              </SvgIcon>
            </Box>
          ) : ''}
          <Button
            variant="contained"
            //   disabled={true}
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </Box>
        <Box sx={{ mb: "32px" }}>
          <ResetPassword />
        </Box>
        <Box sx={{ mb: "32px" }}>
          <SettingsLogout />
        </Box>
      </Box>
    </Box>
  );
};

export default Settings;

const schema = yup.object().shape({
  email: yup.string(),
  phoneNumber: yup
    .string()
    .min(10, "Please enter a 10-digit phone number")
    .required("Phone number is required")
    .matches(phoneRegex, "Phone number is not valid")
    .max(12, "Phone number should not exceed 12 numbers"),
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  serviceAreaId: yup.string().required("This is required"),
  saveMethod: yup.boolean(),
  termsService: yup.boolean(),
});
